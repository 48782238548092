import { memo } from 'react';
import educazione from '@Icons/educazione.png';
import editoria from '@Icons/editoria.png';
import facilityManagement from '@Icons/facility-management.png';
import legale from '@Icons/legale.png';
import marketing from '@Icons/marketing.png';
import publicRelations from '@Icons/public-relations.png';
import risorseUmane from '@Icons/risorse-umane.png';
import vendite from '@Icons/vendite.png';

const Icons = {
  educazione,
  editoria,
  facilityManagement,
  legale,
  marketing,
  publicRelations,
  risorseUmane,
  vendite,
};

function Icon({ icon }) {
  return (
    <div className="w-8 h-8 p-6 bg-[#02ac92] rounded-[20px] justify-center items-center flex">
      <img alt={icon} src={Icons[icon]} style={{ width: '32px' }} />
    </div>
  );
}

export default memo(Icon);
