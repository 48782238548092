import { memo, useState } from 'react';
import logo from '@Images/logo.png';
import logoNegative from '@Images/logo-negative.png';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';
import PurpleButton from '@Components/purple-button';

const BLACK_GRADIENT = 'black-gradient';
const FONT_LIGHT = 'font-light';
const TEXT_WHITE = 'text-white';

const initialMenuEntryClassName = [
  'cursor-pointer',
  FONT_LIGHT,
  'font-serenity',
  'menu-entry',
  TEXT_WHITE,
  'text-xl',
];

const initialNavbarClassNames = [
  'backdrop-blur-[20px]',
  BLACK_GRADIENT,
  'fixed',
  'gap-[72px]',
  'h-[61px]',
  'inline-flex',
  'items-center',
  'justify-start',
  'px-4',
  'py-2',
  'z-10',
];

function Navbar() {
  const navigate = useNavigate();
  const [navbarClassNames, setNavbarClassNames] = useState(initialNavbarClassNames);
  const [menuEntryClassNames, setMenuEntryClassNames] = useState(initialMenuEntryClassName);
  const [logoNavbar, setLogoNavbar] = useState(logo);

  const handleOnMouseOver = () => {
    setNavbarClassNames((old) => old.filter((e) => e !== BLACK_GRADIENT).concat('bg-white'));
    setMenuEntryClassNames((old) => [
      ...old.filter((e) => ![TEXT_WHITE, FONT_LIGHT].includes(e)),
      'text-[#901d70]',
      'font-bold',
    ]);
    setLogoNavbar(logoNegative);
  };
  const handleOnMouseOut = () => {
    setNavbarClassNames((old) => old.filter((e) => e !== 'bg-white').concat(BLACK_GRADIENT));
    setMenuEntryClassNames((old) => [
      ...old.filter((e) => !['text-[#901d70]', 'font-bold'].includes(e)),
      TEXT_WHITE,
      FONT_LIGHT,
    ]);
    setLogoNavbar(logo);
  };
  const handleOnBlurFocus = () => {};

  const handleNavigateHome = () => { navigate(PATHS.HOME); };
  const handleNavigateAIChatBuilder = () => { navigate(PATHS.AI_CHAT_BUILDER); };

  const handleNavigateAIOperatingSystem = () => { navigate(PATHS.AI_OPERATING_SYSTEM); };

  const handleNavigateContattaci = () => { navigate(PATHS.CONTATTACI); };

  return (
    <div
      className={navbarClassNames.join(' ')}
      id="navbar"
      onBlur={handleOnBlurFocus}
      onFocus={handleOnBlurFocus}
      onMouseOut={handleOnMouseOut}
      onMouseOver={handleOnMouseOver}
      style={{
        boxShadow: '0 6px 2px -2px black',
        width: '98.5%',
      }}
    >
      <img alt="logo" id="logo" src={logoNavbar} style={{ height: '4rem' }} />

      <div className={menuEntryClassNames.join(' ')} onClick={handleNavigateHome}>HOME</div>

      <div className={menuEntryClassNames.join(' ')} onClick={handleNavigateAIOperatingSystem}>AI OPERATING SYSTEM</div>

      <div className={menuEntryClassNames.join(' ')} onClick={handleNavigateAIChatBuilder}>AI CHAT BUILDER</div>

      <div className="flex-grow" />

      <PurpleButton onClick={handleNavigateContattaci} text="CONTATTACI" />
    </div>
  );
}

export default memo(Navbar);
