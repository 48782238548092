import Spacer from '@Components/spacer';
import { memo } from 'react';
import ListEntry from '@Components/grid-list/list-entry';

function Page3() {
  return (
    <div className="z-0 p-7">
      <div
        className="text-center text-[#262525] text-5xl font-bold font-serenity"
        style={{ height: '125px', lineHeight: '58px' }}
      >
        Aiutare le piccole e grandi industrie
        <br />
        a brillare con la tecnologia AI
      </div>

      <Spacer space="3.125rem" />

      <div className="flex flex-col gap-20">
        <div className="flex flex-row justify-center gap-20">

          <ListEntry icon="educazione" title="Educazione">
            Creare contenuti didattici coinvolgenti e promuovere esperienze di apprendimento interattive con agenti di intelligenza artificiale che collegano studenti, insegnanti e libri di testo.
          </ListEntry>

          <ListEntry icon="legale" title="Legale">
            Generare documenti precisi e consentire agli agenti AI di assistere avvocati e clienti nelle ricerche e nella gestione dei casi.
          </ListEntry>

        </div>

        <div className="flex flex-row justify-center gap-20">
          <ListEntry icon="editoria" title="Editoria">
            Accelerate la creazione di contenuti e mantenete la coerenza del marchio, consentendo agli agenti di intelligenza artificiale di assistere autori, editori e lettori.
          </ListEntry>

          <ListEntry icon="risorseUmane" title="Risorse Umane">
            Semplificate i processi HR con gli agenti AI che creano contenuti personalizzati per il reclutamento, la formazione e il coinvolgimento dei dipendenti.
          </ListEntry>
        </div>

        <div className="flex flex-row justify-center gap-20">
          <ListEntry icon="vendite" title="Vendite">
            Potenziate
            il vostro team di vendita con agenti di intelligenza artificiale che creano contenuti persuasivi,
            interagiscono con i potenziali clienti e forniscono informazioni in tempo reale per chiudere le
            trattative.
          </ListEntry>

          <ListEntry icon="facilityManagement" title="Facility Management">
            Ottimizzate le operazioni con gli agenti AI che generano report, interagiscono con il personale e gli inquilini e creano programmi di manutenzione.
          </ListEntry>
        </div>

        <div className="flex flex-row justify-center gap-20">
          <ListEntry icon="marketing" title="Marketing">
            Producete
            contenuti di marketing mirati su scala e distribuite gli agenti AI per coinvolgere i clienti su più
            canali.
          </ListEntry>

          <ListEntry icon="publicRelations" title="Public Relations">
            Creare
            narrazioni e comunicati stampa convincenti, utilizzando gli agenti di intelligenza artificiale per
            monitorare il sentiment del marchio e interagire con i contatti dei media.
          </ListEntry>
        </div>
      </div>
    </div>
  );
}

export default memo(Page3);
