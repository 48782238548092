import { memo } from 'react';
import Header from '@Containers/ai-chat-builder/header';
import WhitePage from '@Containers/ai-chat-builder/white-page';
import BlackPage from '@Containers/ai-chat-builder/black-page';
import ImgMotoreLLM from '@Images/motore-llm.png';
import ImgCreaKnowledgeBase from '@Images/crea-knowledge-base.png';
import ImgPersonalizzareComportamenti from '@Images/personalizzare-comportamenti-istruzioni.png';
import ImgCreareProprioStile from '@Images/creare-proprio-stile.png';
import ImgCondivideteUtilizzate from '@Images/condividete-utilizzate.png';
import ImgStessaEsprienzaSmartphone from '@Images/stessa-esperienza-smartphone.png';
import Spacer from '@Components/spacer';
import PurpleButton from '@Components/purple-button';
import BulletedList from '@Components/bulleted-list';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';
import Footer from '@Components/Footer';

const bulletedItems = [
  "Design reattivo: un'esperienza utente senza soluzione di continuità sul vostro smartphone, grazie al design reattivo di Oribea Chatbot che si adatta alle varie dimensioni dello schermo.",
  "Interfaccia mobile intuitiva: navigate senza fatica con un'interfaccia mobile intuitiva, che garantisce interazioni facili da usare anche in movimento.",
  'Funzionalità coerente: sperimentate funzionalità e caratteristiche coerenti su tutti i dispositivi, fornendo un percorso utente unificato e accessibile sul vostro smartphone.',
];

function AIChatBuilder() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <div className="flex flex-col">
      <Header />

      <WhitePage image={ImgMotoreLLM} title="Scegliere un motore LLM e inizializzare il chatbot">
        Scegliere un motore LLM e inizializzare il chatbot
        <br />
        Selezionate il vostro motore LLM preferito e avviate il vostro chatbot. La
        nostra interfaccia user-friendly vi permette di inizializzare il sistema senza sforzo, consentendovi di
        personalizzare le capacità del vostro chatbot per soddisfare le vostre esigenze e i vostri obiettivi
      </WhitePage>

      <BlackPage image={ImgCreaKnowledgeBase} title="Creare una Knowledge Base con file, testi e link personalizzati">
        Create una solida Knowledge Base con file, testi e link di vostra scelta. Oribea Chatbot vi consente di creare
        un archivio su misura per le vostre esigenze, contenente le informazioni più rilevanti per voi e per la vostra
        azienda
      </BlackPage>

      <WhitePage
        image={ImgPersonalizzareComportamenti}
        title="Personalizzare comportamenti e funzioni (se necessario!)"
      >
        Adattate i comportamenti e le funzioni alle vostre specifiche senza sforzo. Oribea Chatbot consente una
        personalizzazione avanzata dei comportamenti del chatbot, fino all&apos;applicazione di funzioni personalizzate
        per gli sviluppatori più esperti e per le esigenze più specifiche.
      </WhitePage>

      <BlackPage
        image={ImgCreareProprioStile}
        inverted
        title="Creare il proprio stile con il logo e i colori del marchio"
      >
        Fatevi riconoscere dai vostri clienti e utenti e create il vostro stile unico incorporando il vostro logo e i
        colori del vostro marchio. Oribea Chatbot vi permette di personalizzare ogni dettaglio, colore e modalità scura,
        assicurandovi un look and feel coerente e in linea con il marchio, che risuona con il vostro pubblico.
      </BlackPage>

      <WhitePage
        image={ImgCondivideteUtilizzate}
        title="Condividete e utilizzate il vostro nuovo chatbot tramite link o sul vostro sito web"
      >
        Condividete e distribuite senza problemi il vostro nuovo chatbot tramite link o incorporatelo senza problemi nel
        vostro sito web. Migliorate il coinvolgimento degli utenti e semplificate le interazioni con uno strumento
        versatile e accessibile ovunque sia necessario.
      </WhitePage>

      <div className="black-gradient">
        <Spacer space="4.5rem" />

        <div className="flex flex-row justify-center">
          <img alt="" height="1000px" src={ImgStessaEsprienzaSmartphone} />

          <div className="flex flex-col items-center">
            <div
              className="text-center text-white text-5xl font-bold font-serenity"
              style={{ width: '915px', height: '125px', lineHeight: '58px' }}
            >
              La stessa esperienza sul tuo
              <br />
              smartphone!
            </div>

            <div className="text-center text-white text-base font-light font-arbotek">
              Un nuovo sistema di Chatbot che puoi creare sulla base delle tue esigenze principali
            </div>

            <Spacer space="2rem" />

            <div className="flex flex-row">
              <PurpleButton onClick={navigateToContactPage} text="CREA IL TUO CHATBOT PERSONALIZZATO" />
            </div>

            <Spacer space="2.625rem" />

            <BulletedList texts={bulletedItems} />

          </div>
        </div>

        <Footer />

      </div>
    </div>
  );
}

export default memo(AIChatBuilder);
