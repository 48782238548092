import { memo } from 'react';
import TabsLastPage from '@Images/tabs-last-page.png';
import PurpleButton from '@Components/purple-button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';

function Page4() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <div className="black-gradient px-8">
      <div className="flex flex-row justify-center items-center gap-14" style={{ height: '70%' }}>
        <div className="flex flex-col">
          <div className="h-[58px] p-2.5 bg-white rounded-lg justify-center items-center gap-2.5 inline-flex">
            <div
              className="text-center text-[#471339] text-[32px] font-bold font-serenity"
              style={{ lineHeight: '38px' }}
            >
              Create e condividete il vostro Chatbot
            </div>
          </div>

          <div className="w-[490px] h-[267px] text-center flex flex-col justify-center">
            <span className="text-white text-2xl font-light font-serenity">
              Costruite chatbot personalizzati da zero, sfruttando le vostre conoscenze e informazioni esistenti!
              Create assistenti virtuali per interazioni intelligenti con il vostro pubblico, costruite un chatbot che rifletta la vostra personalità
              e il vostro marchio, ottimizzate l&apos;assistenza clienti, raccogliete dati e semplificate le operazioni online.
            </span>
          </div>

          <div className="flex flex-row justify-center">
            <PurpleButton onClick={navigateToContactPage} text="INIZIA LA TUA PROVA" />
          </div>
        </div>

        <img alt="tabs" src={TabsLastPage} style={{ width: '50vw' }} />
      </div>

    </div>
  );
}

export default memo(Page4);
