/* eslint-disable */
import { memo, useEffect, useState } from 'react';
import ImgBgPinguino from '@Images/bg-penguin.png';
import Spacer from '@Components/spacer';
import IconMail from '@Icons/mail.png';
import IconPhone from '@Icons/phone.png';
import IconPlane from '@Icons/plane.png';
// import ToggleButton from '@Components/ToggleButton';
import TextField from '@Components/TextField';
import Footer from '@Components/Footer';

function Contact() {
  const [remoteCaptcha, setRemoteCaptcha] = useState('');

  // FORM
  const [name, setName] = useState('');
  const [company, setCompany] = useState('');
  const [website, setWebsite] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [inputCaptcha, setInputCaptcha] = useState('');

  const fetchCaptcha = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/sendmail/getCaptcha.php?m=inline`, {
      credentials: 'include',
    })
      .then((res) => res.text())
      .then((resp) => {
        setRemoteCaptcha(resp.replaceAll('"', ''));
      });
  };

  useEffect(fetchCaptcha, []);

  const handleOnSubmit = () => {
    if (!name || !email || !company || !website || !message || !inputCaptcha) {
      alert('Tutti i campi sono obbligatori');
    }

    fetch(`${process.env.REACT_APP_BASE_URL}/sendmail/send.php`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams({
        name,
        email,
        message,
        website,
        company,
        captcha: inputCaptcha,
      }),
    })
      .then((res) => res.json())
      .then(({ code }) => {
        if (code > 200) {
          alert('Errore nell\' invio del form');
          return;
        }

        setName('');
        setEmail('');
        setMessage('');
        setCompany('');
        setWebsite('');
        setInputCaptcha('');
        fetchCaptcha();
      });
  };

  return (
    <div className="black-gradient flex flex-col">
      <div className="black-gradient z-0 min-h-[900px]">
        <img align="right" alt="navicella" src={ImgBgPinguino} width="40%" />

        <div
          className="absolute top-[70%] left-2/4 rounded-3xl purple-gradient p-16 z-20 flex flex-row gap-12"
          style={{ transform: 'translate(-50%, -50%)' }}
        >
          <div className="flex flex-col">
            <div className="w-[400px] text-[#eeeeee] text-3xl font-light font-serenity">
              Let’s discuss
              {' '}

              <br />

              on something
              {' '}

              <span className="brand-to-white-cool text-[64px] font-black">
                cool
              </span>

              {' '}
              together
            </div>

            <Spacer space="5.5rem" />

            <div className="flex flex-col gap-6">
              <div className="flex flex-row items-center gap-4 p-6">
                <img alt="e-mail" className="w-6 h-6" src={IconMail} />

                <div className="text-white text-2xl font-semibold font-serenity">marco.macari@oribea.ai</div>
              </div>

              <div className="flex flex-row items-center gap-4 p-6">
                <img alt="e-mail" className="w-6 h-6" src={IconPhone} />

                <div className="text-white text-2xl font-semibold font-serenity">+39 335 738 48 15</div>
              </div>
            </div>
          </div>

          <div className="w-[600px] h-[450px] bg-[#eeeeee] rounded-3xl p-12 flex flex-col overflow-y-scroll">
            {/* <div className="text-[#6b1a54] text-xl font-medium font-poppins mb-[1.5rem]">I’m interested in...</div> */}

            {/* <div className="flex flex-row gap-4 mb-4"> */}
            {/*  <ToggleButton text="UX/UI design" /> */}

            {/*  <ToggleButton text="Web design" /> */}

            {/*  <ToggleButton text="Graphic design" /> */}
            {/* </div> */}

            {/* <div className="flex flex-row gap-4 mb-16"> */}
            {/*  <ToggleButton text="Design system" /> */}

            {/*  <ToggleButton text="Other" /> */}
            {/* </div> */}

            <div className="mb-16">
              <TextField placeholder="Your name" setValue={setName} value={name} />
            </div>

            <div className="mb-16">
              <TextField placeholder="Your company name" setValue={setCompany} value={company} />
            </div>

            <div className="mb-16">
              <TextField placeholder="Your website" setValue={setWebsite} value={website} />
            </div>

            <div className="mb-16">
              <TextField placeholder="Your email" setValue={setEmail} value={email} />
            </div>

            <TextField placeholder="Your message" setValue={setMessage} value={message} />

            {remoteCaptcha && (
              <div className="flex flex-row gap-4 mt-16">
                <img alt="captcha" className="w-[150px]" src={remoteCaptcha} />

                <TextField placeholder="Enter CAPTCHA" setValue={setInputCaptcha} value={inputCaptcha} />
              </div>
            )}

            <div className="flex mt-32">
              <div
                className="cursor-pointer p-6 bg-[#901d70] rounded-2xl flex flex-row gap-4"
                onClick={handleOnSubmit}
              >
                <img alt="send" className="w-6 h-6" src={IconPlane} />

                <div className="text-center text-[#eeeeee] text-xl font-medium font-poppins">Send Message</div>
              </div>
            </div>

          </div>

        </div>
      </div>

      <Footer />
    </div>
  );
}

export default memo(Contact);
