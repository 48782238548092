import { memo } from 'react';
import BulletedList from '@Components/bulleted-list';
import Spacer from '@Components/spacer';
import PurpleButton from '@Components/purple-button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';

const bulletedListLeft = [
  'Create il vostro chatbot in base alle conoscenze e ai documenti della vostra azienda',
  'Utilizzate la potenza di Analytics e Statistiche per migliorare il vostro Chatbot',
  'Aggiungete un messaggio extra e un agente extra se necessario',
  'Scegliere tra più selezioni di LLM',
  'Condividete il vostro chatbot online',
];

const bulletedListRight = [
  (
    <>
      Automazione dei compiti
      <br />
      Gestite le attività ripetitive, aumentando l&apos;efficienza e riducendo gli errori.
    </>
  ),
  (
    <>
      Esperienza utente personalizzata
      <br />
      Adatta i servizi e i contenuti alle preferenze degli utenti, migliorandone la soddisfazione.
    </>
  ),
  (
    <>
      Innovazione tecnologica
      <br />
      Accelera lo sviluppo in tutti i campi, portando avanti soluzioni innovative e trasformative.
    </>
  ),
];

function Page2() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <>
      <div className="flex flex-row justify-between px-8">
        <div className="flex flex-col items-center">
          <div
            className="text-center text-white text-5xl font-bold font-serenity"
            style={{ height: '125px', lineHeight: '58px' }}
          >
            Il Chatbot intelligente
            <br />
            il nostro sistema di AI Builder
          </div>

          <div className="min-w-[814px] text-center text-white text-base font-medium font-arbotek">
            Un nuovo sistema di Chatbot che puoi creare
            <br />
            sulla base delle tue esigenze principali

            <br />

            <br />
          </div>

          <div style={{ height: '2rem' }} />

          <PurpleButton onClick={navigateToContactPage} text="CREA IL TUO CHATBOT PERSONALIZZATO" />

          <div style={{ height: '2rem' }} />

          <BulletedList texts={bulletedListLeft} />

        </div>

        <div className="flex flex-col items-center">
          <div
            className="text-center text-white text-5xl font-bold font-serenity"
            style={{ height: '125px', lineHeight: '58px' }}
          >
            Tutti i vantaggi dell&apos;intelligenza
            <br />
            artificiale
          </div>

          <div className="max-w-[734px] text-center text-white text-base font-light font-arbotek">
            L&apos;intelligenza
            artificiale rivoluziona il nostro modo di lavorare. Dall&apos;automazione dei processi all&apos;analisi
            avanzata dei dati, l&apos;IA migliora l&apos;efficienza, promuove l&apos;innovazione e crea nuove
            opportunità
            in un&apos;ampia gamma di settori.
          </div>

          <div style={{ height: '2rem' }} />

          <PurpleButton onClick={navigateToContactPage} text="INIZIA LA TUA PROVA" />

          <Spacer space="2rem" />

          <BulletedList texts={bulletedListRight} />
        </div>
      </div>

      <Spacer space="8.5rem" />

      <div className="flex flex-row justify-center gap-12">
        <div className="p-8 purple-gradient rounded-3xl justify-center items-center gap-2.5 inline-flex">
          <div className="text-white text-5xl font-bold font-poppins">100+</div>

          <div className="text-white text-2xl font-light font-serenity">Attività pronte all’uso</div>
        </div>

        <div className="p-8 purple-gradient rounded-3xl justify-center items-center gap-2.5 inline-flex">
          <div className="text-white text-5xl font-bold font-poppins">30+</div>

          <div className="text-white text-2xl font-light font-serenity">AI Personas</div>
        </div>

        <div className="p-8 purple-gradient rounded-3xl justify-center items-center gap-2.5 inline-flex">
          <div className="text-white text-5xl font-bold font-poppins">50+</div>

          <div className="text-white text-2xl font-light font-serenity">Chatbot costruiti</div>
        </div>
      </div>

      <Spacer space="15rem" />
    </>
  );
}

export default memo(Page2);
