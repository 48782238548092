import { memo } from 'react';
import Spacer from '@Components/spacer';
import BrandToBlack from '@Components/BrandToBlack';
import PurpleButton from '@Components/purple-button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';

function Page1() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <div className="black-gradient z-0">
      <Spacer space="61px" />

      <div className="flex flex-col items-center mt-10">
        <div
          className="text-center text-white text-5xl font-bold font-serenity"
          style={{ height: '125px', lineHeight: '58px' }}
        >
          Costruisci il tuo Chatbot
          <br />
          personalizzato al 100%
        </div>

        <Spacer space="1.5rem" />

        <div
          className="px-6 pt-6 pb-8 bg-white/10 rounded-3xl backdrop-blur-[60px] flex-col justify-start items-center gap-7 inline-flex"
        >
          <div className="w-[581px] self-stretch justify-center items-center gap-[9px] inline-flex">
            <div className="w-[490px] text-center text-white text-2xl font-bold font-serenity">
              Scoprite il futuro
              della comunicazione con Oribea Chatbot! Create chatbot personalizzati da zero, utilizzando le vostre
              conoscenze e informazioni precedenti, secondo il principio RAG. Oribea è la piattaforma di facile
              utilizzo
              che vi permette di:
            </div>
          </div>
        </div>

        <Spacer space="1.5rem" />

        <PurpleButton onClick={navigateToContactPage} text="CREA IL TUO CHATBOT" />

        <Spacer space="7.125rem" />

        <div className="flex flex-row gap-9">

          <BrandToBlack image="meteora">
            Creare assistenti virtuali per
            <br />
            interazioni intelligenti con il vostro

            <br />
            pubblico
          </BrandToBlack>

          <BrandToBlack image="pianetaVioletto">
            Ottimizzare l&apos;assistenza clienti,
            <br />

            raccogliere dati e semplificare le
            <br />
            operazioni online
          </BrandToBlack>

          <BrandToBlack image="pianetaVerde">
            Costruire un chatbot che riflettaì
            <br />
            la vostra personalità e il vostro

            <br />
            marchio
          </BrandToBlack>

        </div>

        <Spacer space="7.5rem" />

      </div>

    </div>
  );
}

export default memo(Page1);
