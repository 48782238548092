import { memo } from 'react';
import withKeys from '@Src/utils/withKeys';
import ListEntry from './list-entry';

const className = [
  'backdrop-blur-[60px]',
  'bg-white/10',
  'flex-col',
  'gap-7',
  'inline-flex',
  'items-start',
  'justify-start',
  'max-w-xl',
  'pb-8',
  'pt-6',
  'px-6',
  'rounded-3xl',
].join(' ');

function BulletedList({ texts }) {
  return (
    <div className={className}>
      {withKeys(
        texts.map((text) => <ListEntry text={text} />),
      )}
    </div>
  );
}

export default memo(BulletedList);
