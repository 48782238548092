import { memo } from 'react';

function BlackPage({
  title, children, image, inverted = false,
}) {
  return (
    <div className="black-gradient py-[4.5rem]">
      <div className={`flex ${inverted ? 'flex-row-reverse' : 'flex-row'} justify-between px-[142px] gap-12`}>
        <div className="flex flex-col gap-5">
          <div className="brand-to-white-text text-[64px] font-bold font-serenity">
            {title}
          </div>

          <div className="text-white text-[32px] font-extralight font-serenity">
            {children}
          </div>

        </div>

        <img alt={title} className="w-[350px]" src={image} />
      </div>
    </div>
  );
}

export default memo(BlackPage);
