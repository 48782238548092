import { memo } from 'react';

function PurpleButton({ text, onClick }) {
  return (
    <div
      className="cursor-pointer inline-flex justify-center items-center gap-2.5 p-3 rounded-tl-lg rounded-br-lg purple-gradient"
      onClick={onClick}
    >
      <div className="text-white text-base font-serenity font-bold">{text}</div>
    </div>
  );
}

export default memo(PurpleButton);
