import { memo } from 'react';
import Spacer from '@Components/spacer';
import PurpleButton from '@Components/purple-button';
import OutlinedButton from '@Components/outlined-button';
import ImgSistemaOperativo from '@Images/sistema-operativo.png';
import ImgPinguino from '@Images/penguin.png';
import ImgStelline from '@Images/stelline.png';
import ImgGhosts from '@Images/ghosts.png';
import ImgFolder from '@Images/folder.png';
import ImgCalendar from '@Images/calendar.png';
import ImgMoon from '@Images/moon.png';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';
import Footer from '@Components/Footer';

function AiOperatingSystem() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <div className="flex flex-col">
      <div className="px-12 black-gradient z-0">
        <Spacer space="138px" />

        <div
          className="brand-to-white-text text-5xl font-bold font-serenity"
          style={{ height: '125px', lineHeight: '58px' }}
        >
          Sistema operativo
          <br />
          Oribea AI
        </div>

        <Spacer space="77px" />

        <div className="flex flex-row gap-5">
          <div
            className="w-[912px] h-[245px] px-6 pt-6 pb-8 bg-white/10 rounded-3xl backdrop-blur-[60px] flex-col justify-start items-center gap-7 inline-flex"
          >
            <div className="self-stretch justify-start items-center gap-[9px] inline-flex">
              <div className="w-[742px] text-[#009eb9] text-2xl font-bold font-['Poppins']">VANTAGGI</div>
            </div>

            <div className="self-stretch justify-start items-center gap-[9px] inline-flex">
              <div className="w-[742px] text-white text-2xl font-bold font-['Serenity']">
                La potenza dell&apos;intelligenza artificiale per i vostri progetti professionali
                <br />
                Aumentate la produttività con soluzioni personalizzate in base alle specifiche esigenze di lavoro.
                Create testi e immagini personalizzati, lavorate con cartelle e progetti, assistiti dall&apos;infinita
                potenza dell&apos;intelligenza artificiale.
              </div>
            </div>
          </div>

          <div
            className="w-[615px] h-[245px] p-6 bg-white/10 rounded-3xl flex-col justify-start items-start gap-[22px] inline-flex"
          >
            <div className="self-stretch text-[#009eb9] text-2xl font-bold font-['Poppins']">
              CARATTERISTICHE
              PRINCIPALI
            </div>

            <div className="w-[568px] text-white text-2xl font-bold font-['Serenity']">
              Oribea è all&apos;avanguardia nei
              progressi dell&apos;IA generativa e offre uno spettro di funzionalità gratuite e premium, tutte realizzate
              per
              offrire agli utenti un viaggio personalizzato e senza soluzione di continuità.
            </div>
          </div>
        </div>

        <Spacer space="43px" />

        <div className="flex flex-row justify-center">
          <img alt="sistema operativo" src={ImgSistemaOperativo} width={1208} />
        </div>

        <div className="flex flex-row justify-center gap-7">
          <PurpleButton onClick={navigateToContactPage} text="CREA IL TUO CHATBOT" />

          <OutlinedButton text="SCOPRI IL NOSTRO ORIBEA AI" />
        </div>

        <Spacer space="54px" />
      </div>

      <div className="flex flex-col items-center p-8">
        <div
          className="h-[100px] text-[#901d70] text-5xl font-bold font-serenity"
          style={{ lineHeight: '58px' }}
        >
          AI Personas
        </div>

        <div className="max-w-[1100px] h-[109px] text-center text-[#262525] text-[32px] font-extralight font-serenity">
          Con Oribea si ha accesso a varie personalità AI, ognuna specializzata in campi diversi, in grado di creare
          contenuti su misura
        </div>

        <Spacer space="48px" />

        <div className="w-full flex flex-row justify-between">
          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={102} src={ImgPinguino} width={77} />

            <div className="flex flex-col">
              <span className="text-black text-2xl font-bold font-serenity">
                Definire il vostro obiettivo
              </span>

              <span className="text-black text-2xl font-light font-serenity">
                Scegliete tra una serie di entità AI, ognuna dotata di competenze specifiche
              </span>
            </div>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={78} src={ImgStelline} width={85} />

            <div className="flex flex-col">
              <span className="text-black text-2xl font-bold font-serenity">
                Definire il vostro obiettivo
              </span>

              <span className="text-black text-2xl font-light font-serenity">
                Scegliete tra una serie di entità AI, ognuna dotata di competenze specifiche
              </span>
            </div>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={80} src={ImgGhosts} width={77} />

            <div className="flex flex-col">
              <span className="text-black text-2xl font-bold font-serenity">
                Definire il vostro obiettivo
              </span>

              <span className="text-black text-2xl font-light font-serenity">
                Scegliete tra una serie di entità AI, ognuna dotata di competenze specifiche
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="black-gradient flex flex-col items-center">
        <Spacer space="56px" />

        <div
          className="h-[100px] text-white text-5xl font-bold font-serenity"
          style={{ lineHeight: '58px' }}
        >
          +100 Tasks pronte all’uso!
        </div>

        <div className="max-w-[1100px] h-[109px] text-center text-white text-[32px] font-extralight font-serenity">
          Oribea offre oltre 100 compiti predefiniti che guidano l&apos;interazione con l&apos;IA, adattandosi a vari
          contesti aziendali come HR, Legale, Finanza, Vendite, Marketing e molti altri.
        </div>

        <Spacer space="48px" />

        <div className="flex flex-row gap-16">
          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={78} src={ImgStelline} width={85} />

            <div className="text-white text-2xl font-light font-serenity">
              <strong>Ogni task è personalizzabile</strong>

              {' '}
              con i vostri dati, consentendovi di ottenere risultati su misura per le vostre esigenze.
            </div>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={80} src={ImgGhosts} width={77} />

            <div className="flex flex-col">
              <div className="text-white text-2xl font-light font-serenity">
                Gli utenti dell&apos;azienda possono
                {' '}

                <strong>anche creare i propri modelli,</strong>

                {' '}
                sfruttando le loro competenze nel settore.
              </div>
            </div>
          </div>
        </div>

        <Spacer space="56px" />
      </div>

      <div className="flex flex-col items-center p-8">
        <div
          className="h-[100px] text-[#901d70] text-5xl font-bold font-serenity"
          style={{ lineHeight: '58px' }}
        >
          Conversazioni Fluide
        </div>

        <div className="max-w-[1100px] h-[109px] text-center text-[#262525] text-[32px] font-extralight font-serenity">
          Conversazioni fluide con Oribea, un sistema progettato per interazioni dinamiche che ricordano gli scambi
          precedenti.
        </div>

        <Spacer space="48px" />

        <div className="w-full flex flex-row justify-between">
          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={102} src={ImgPinguino} width={77} />

            <span className="text-black text-2xl font-bold font-serenity">
              Chattare senza vincoli
            </span>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={78} src={ImgStelline} width={85} />

            <div className="text-black text-2xl font-bold font-serenity">
              Memoria perfetta dei dialoghi passati
            </div>
          </div>

          <div className="w-[370px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={80} src={ImgGhosts} width={77} />

            <div className="text-black text-2xl font-bold font-serenity">
              Risultati altamente personalizzati grazie agli strumenti avanzati di Oribea
            </div>
          </div>
        </div>
      </div>

      <div className="black-gradient flex flex-col items-center">
        <Spacer space="56px" />

        <div
          className="h-[100px] text-white text-5xl font-bold font-serenity"
          style={{ lineHeight: '58px' }}
        >
          Knowledge Hub
        </div>

        <div className="max-w-[1128px] h-[109px] text-center text-white text-[32px] font-extralight font-serenity">
          Massimizzate le prestazioni di Oribea con Knowledge Hub, creato su misura per assimilare i contenuti
          specifici della vostra azienda (come documenti, documenti, PDF e altro):
        </div>

        <Spacer space="48px" />

        <div className="w-full flex flex-row justify-between">
          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={78} src={ImgStelline} width={85} />

            <div className="flex flex-col">
              <span className="text-white text-2xl font-bold font-serenity">
                Fase 1
              </span>

              <span className="text-white text-2xl font-light font-serenity">
                Archiviazione semplificata dei dati
                Archiviate in modo semplice e sicuro i dettagli essenziali dell&apos;azienda o del prodotto all&apos;interno di Knowledge Hub.
              </span>
            </div>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={80} src={ImgGhosts} width={73} />

            <div className="flex flex-col">
              <span className="text-white text-2xl font-bold font-serenity">
                Fase 2
              </span>

              <span className="text-white text-2xl font-light font-serenity">
                Integrazione semplice delle informazioni
                Utilizzate il simbolo &apos;@&apos; per estrarre e integrare senza problemi le informazioni dal Knowledge Hub, assicurando che Oribea catturi il vostro stile distintivo durante la creazione dei contenuti.
              </span>
            </div>
          </div>

          <div className="max-w-[474px] flex flex-row items-center gap-2.5 p-8">
            <img alt="navicella" height={102} src={ImgPinguino} width={77} />

            <div className="flex flex-col">
              <span className="text-white text-2xl font-bold font-serenity">
                Fase 3
              </span>

              <span className="text-white text-2xl font-light font-serenity">
                Raffinamento avanzato dei contenuti
                Osservate Oribea che sfrutta i dati di Knowledge Hub per produrre contenuti ricchi di contesto e coerenti
              </span>
            </div>
          </div>

        </div>

        <Spacer space="64px" />

        <div
          className="h-[100px] text-white text-5xl font-bold font-serenity"
          style={{ lineHeight: '58px' }}
        >
          Il modo migliore per gestire il vostro lavoro
        </div>

        <div className="max-w-[1128px] h-[109px] text-center text-white text-[32px] font-extralight font-serenity">
          Oribea offre uno strumento intuitivo e versatile che semplifica la gestione dei progetti, dai documenti alle
          attività, aiutandovi a mantenere tutto organizzato.
        </div>

        <Spacer space="48px" />

        <div className="flex flex-row gap-[29px]">

          <div className="p-6 bg-[#901d70] rounded-xl flex-col justify-start items-start gap-12 inline-flex">
            <img alt="cartella" className="w-[114px] h-[114px]" src={ImgFolder} />

            <div className="w-[371px] text-white text-xl font-light font-['Poppins']">
              Con la funzione di cartella di
              Oribea, potete organizzare e semplificare i vostri progetti e documenti senza sforzo. Questo sistema
              intuitivo permette di classificare tutto in modo ordinato, garantendo un accesso tempestivo e un flusso di
              lavoro efficiente, consentendovi di concentrarvi su ciò che conta davvero.
            </div>
          </div>

          <div className="p-6 bg-[#901d70] rounded-xl flex-col justify-start items-start gap-12 inline-flex">
            <img alt="calendario" className="w-[114px] h-[114px]" src={ImgCalendar} />

            <div className="w-[371px] text-white text-xl font-light font-poppins">
              Il blocco note di Oribea non serve solo per annotare note veloci. Approfondite l&apos;affinamento e
              il miglioramento dei vostri contenuti con la potenza dell&apos;intelligenza artificiale.
              Ogni informazione è a portata di mano, allineata in armonia con i progetti organizzati,
              rendendo tutto perfettamente interconnesso.
            </div>
          </div>

          <div className="p-6 bg-[#901d70] rounded-xl flex-col justify-start items-start gap-12 inline-flex">
            <img alt="luna" className="w-[114px] h-[114px]" src={ImgMoon} />

            <div className="w-[371px] text-white text-xl font-light font-['Poppins']">
              Oribea abbraccia un pubblico globale con le sue capacità di AI multilingue. Ora interagite senza problemi
              in inglese, italiano, spagnolo, estone, serbo, portoghese, francese, tedesco, greco, marathi, indonesiano,
              giapponese, olandese, polacco, rumeno, turco e arabo. Con Oribea, le barriere linguistiche appartengono al
              passato e consentono un&apos;esperienza universalmente intuitiva.
            </div>
          </div>

        </div>

        <Spacer space="86px" />

      </div>

      <div className="black-gradient">
        <Footer />
      </div>
    </div>
  );
}

export default memo(AiOperatingSystem);
