import pianeta from '@Images/pianeta.png';
import tabsBianche from '@Images/tabs_bianche.png';
import { memo } from 'react';
import PurpleButton from '@Components/purple-button';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '@Src/routes';

function Page1() {
  const navigate = useNavigate();

  const navigateToContactPage = () => {
    navigate(PATHS.CONTATTACI);
  };

  return (
    <div className="flex flex-row">
      <img alt="planet" src={pianeta} style={{ height: '120vh' }} />

      <div className="mt-20 flex flex-col gap-6 items-center">
        <div>
          <div
            className="text-center text-white text-5xl font-bold font-serenity"
            style={{ height: '125px', lineHeight: '58px' }}
          >
            Tutta la potenza dell&apos;IA generativa
            <br />
            combinata con i vostri dati
          </div>

          <div className="text-center text-white text-base font-light font-arbotek">
            Trasformare le soluzioni di
            Intelligenza
            Artificiale aziendali con Agent-as-a-Service
          </div>
        </div>

        <PurpleButton onClick={navigateToContactPage} text="INIZIA OGGI" />

        <img
          alt="tabs"
          className="absolute"
          id="tabs-bianche"
          src={tabsBianche}
          style={{ width: '50vw' }}
        />
      </div>
    </div>
  );
}

export default memo(Page1);
