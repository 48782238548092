import { memo } from 'react';
import check from '@Images/check.png';

function ListEntry({ text }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <img alt="check" src={check} style={{ width: '2.25rem', height: '2.25rem' }} />

      <div className="text-white text-2xl font-bold font-serenity">
        {text}
      </div>
    </div>
  );
}

export default memo(ListEntry);
