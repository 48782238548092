import Spacer from '@Components/spacer';
import Footer from '@Components/Footer';
import Page1 from './page1';
import Page2 from './page2';
import Page3 from './page3';
import Page4 from './page4';

function Home() {
  return (
    <>
      <div className="flex flex-col black-gradient z-0">
        <Spacer space="61px" />

        <Page1 />

        <Page2 />
      </div>

      <Page3 />

      <Page4 />

      <div className="black-gradient">
        <Footer />
      </div>

    </>
  );
}

export default Home;
