import { memo } from 'react';

const classNames = [
  'w-full',
  'text-[#6b1a54]',
  'text-2xl',
  'font-semibold',
  'font-serenity',
  'bg-transparent',
  'outline-none',
  'placeholder-[#6b1a54]',
  'border-0',
  'border-b-2',
  'border-b-[#6b1a54]',
].join(' ');

function TextField({ placeholder, value, setValue }) {
  const handleOnInput = (e) => {
    setValue(e.target.value);
  };

  return (
    <input
      className={classNames}
      onInput={handleOnInput}
      placeholder={placeholder}
      type="text"
      value={value}
    />
  );
}

export default memo(TextField);
