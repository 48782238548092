import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { PATHS } from '@Src/routes';
import Home from '@Containers/home';
import AIChatBuilder from '@Containers/ai-chat-builder';
import AiOperatingSystem from '@Containers/ai-operating-system';
import Navbar from '@Components/navbar';
import Contact from '@Containers/contact';

function App() {
  return (
    <BrowserRouter>
      <div className="flex flex-col">
        <Navbar />

        <Routes>
          <Route element={<Home />} path={PATHS.HOME} />

          <Route element={<AiOperatingSystem />} path={PATHS.AI_OPERATING_SYSTEM} />

          <Route element={<AIChatBuilder />} path={PATHS.AI_CHAT_BUILDER} />

          <Route element={<Contact />} path={PATHS.CONTATTACI} />
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
