import { memo } from 'react';
import Spacer from '@Components/spacer';

function WhitePage({ title, image, children }) {
  return (
    <>
      <Spacer space="4.25rem" />

      <div className="flex flex-row justify-between px-[142px] gap-12">
        <div className="flex flex-col gap-5">
          <div className="brand-to-black-text text-[64px] font-bold font-serenity">
            {title}
          </div>

          <div className="text-[#262525] text-[32px] font-extralight font-serenity">
            {children}
          </div>

        </div>

        <img alt={title} className="w-[272px] h-auto" src={image} />
      </div>

      <Spacer space="3.125rem" />
    </>
  );
}

export default memo(WhitePage);
