import { memo } from 'react';
import Icon from '@Components/grid-list/icon';

function ListEntry({ icon, title, children }) {
  return (
    <div className="flex flex-row gap-2 items-center">
      <Icon icon={icon} />

      <div className="flex flex-col max-w-[490px]">
        <div className="text-[#02ac92] text-[32px] font-black font-serenity" style={{ lineHeight: '38px' }}>
          {title}
        </div>

        <div className="text-[#262525] text-[24px] font-bold font-serenity" style={{ lineHeight: '28px' }}>
          {children}
        </div>
      </div>
    </div>
  );
}

export default memo(ListEntry);
