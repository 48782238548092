import { memo } from 'react';
import Meteora from '@Images/meteora.png';
import PianetaVioletto from '@Images/pianeta-violetto.png';
import PianetaVerde from '@Images/pianeta-verde.png';

const Images = {
  meteora: Meteora,
  pianetaVioletto: PianetaVioletto,
  pianetaVerde: PianetaVerde,
};

function BrandToBlack({ image, children }) {
  return (
    <div className="p-8 rounded-3xl justify-center items-center gap-2.5 inline-flex brand-to-black">
      <img alt="meteora" className="w-[100px]" src={Images[image]} />

      <div className="text-white text-2xl font-extralight font-serenity">
        {children}
      </div>
    </div>
  );
}

export default memo(BrandToBlack);
