import { memo } from 'react';
import Spacer from '@Components/spacer';
import LogoExtended from '@Images/logo-extended.png';

function Footer() {
  return (
    <>
      <div className="flex flex-row w-full mt-20">
        <Spacer isVertical={false} space="32px" />

        <hr className="w-full" />

        <Spacer isVertical={false} space="32px" />
      </div>

      <div className="flex flex-row justify-between items-center gap-14 pl-8" style={{ height: '26%' }}>
        <img alt="logo-extended" src={LogoExtended} style={{ width: '266px' }} />

        <div className="flex flex-row gap-16">
          <div className="text-white text-xl font-medium font-serenity">
            Oribea AI S.r.l
            <br />
            Iscritta al registro Societ&agrave; al nr. 9657

            <br />
            Via Tre Settembre 99

            <br />
            47891 Dogana, San Marino

            <br />
            Codice OE: 30998
          </div>

          <div className="w-[189px] h-[210px] text-white text-base font-bold font-['Serenity']">
            Terms & Conditions
            <br />

            <br />
            Privacy Policy

            <br />

            <br />

            <a className="no-underline text-white" href="mailto:info@oribea.ai">info@oribea.ai</a>
          </div>

        </div>
      </div>
    </>
  );
}

export default memo(Footer);
